import { Text } from "@radix-ui/themes";
import { IconX } from "@tabler/icons-react";
import { memo, useMemo } from "react";
import * as Ariakit from "@ariakit/react";
import styles from "./ClearableSelect.module.css"

function ClearableSelect({value, onValueChange, placeholder, options}) {
    const currValue = useMemo(() => {
      const option = options?.find(option => option.value === value)
      if (option) {
        return option.label
      }
      return null;
    }, [options, value])
    return (
      <Ariakit.SelectProvider value={value} setValue={(e) => {
        if  (e === "clear") {
            onValueChange("");
        } else {
            onValueChange(e)
        }
      }}
      
      >
        
        <Ariakit.Select className={styles['clearable-button']}   placeholder={placeholder} > {currValue ? <Text>{currValue}</Text> : <Text style={{color: "var(--gray-a10)"}}>{placeholder}</Text>}</Ariakit.Select>
        <Ariakit.SelectPopover gutter={4} sameWidth className={styles["popover"]}>
        {options?.map((option, i) => <Ariakit.SelectItem  key={option.value} className={styles["select-item"]} value={option.value}>{option.label}</Ariakit.SelectItem>)}
        <Ariakit.SelectItem  className={styles["select-item"]} value={"clear"}> <IconX size={16} strokeWidth={2}/>Clear</Ariakit.SelectItem>
        </Ariakit.SelectPopover>
      </Ariakit.SelectProvider>
        // <Select.Root
        //   value={value}
        //   onValueChange={(e) => {
        //     if  (e === "clear") {
        //         onValueChange("");
        //     } else {
        //         onValueChange(e)
        //     }
            
        //   }}
        // >
        //   <Select.Trigger placeholder={placeholder}> {currValue} </Select.Trigger>
        //   <Select.Content>
        //     {options?.map((option, i) => (
        //       <Select.Item key={option.value} value={option.value}>
        //         {option.label}
        //       </Select.Item>
        //     ))}
        //      <Select.Item 
        //               value={"clear"}>
        //                 <Flex direction="row" align="center">
        //                  <IconX size={16} strokeWidth={2}/>
        //                 </Flex>
        //               </Select.Item>
        //   </Select.Content>
        // </Select.Root>
    )
}

export default memo(ClearableSelect)